/* Default/light theme */
:root {
  /* surface */
  --color-global-surface-0: theme(colors.white);
  --color-global-surface-1: 252 252 253;
  --color-global-surface-neutral: theme(colors.core.grey.20);
  --color-global-surface-success: theme(colors.core.green.20);
  --color-global-surface-success-2: theme(colors.core.green.90);
  --color-global-surface-error: theme(colors.core.red.30);
  --color-global-surface-warning: theme(colors.core.orange.20);
  --color-global-surface-warning-2: theme(colors.core.orange.90);
  --color-global-surface-shadow-root: theme(colors.black);
  --color-global-surface-default: theme(colors.core.grey.30);
  --color-global-surface-input-error: theme(colors.core.red.10);
  --color-global-surface-modal: theme(colors.core.grey_variant.120);
  /* text */
  --color-global-text-primary: theme(colors.core.grey.120);
  --color-global-text-secondary: theme(colors.core.grey.110);
  --color-global-text-tertiary: theme(colors.core.grey.100);
  --color-global-text-muted: theme(colors.core.grey.90);
  --color-global-text-disabled: theme(colors.core.grey.80);
  --color-global-text-on-color: theme(colors.core.grey.10);
  --color-global-text-success: theme(colors.core.green.100);
  --color-global-text-error: theme(colors.core.red.100);
  --color-global-text-warning: theme(colors.core.orange.100);
  --color-global-text-danger: theme(colors.core.red.100);
  --color-global-text-white: theme(colors.white);
  --color-global-text-contrast: theme(colors.white);
  /* borders and dividers*/
  --color-global-border-subtle: theme(colors.core.grey.40);
  --color-global-border-default: theme(colors.core.grey.80);
  --color-global-divider: theme(colors.core.grey.30);
  --color-contrast-divider: theme(colors.black);
  --color-global-border-error: theme(colors.core.red.100);
  --color-global-drag-and-drop-divider: theme(colors.core.yellow.40);
  /* action */
  --color-global-action-primary: theme(colors.core.purple.90);
  --color-global-action-primary-hover: theme(colors.core.purple.80);
  --color-global-action-primary-pressed: theme(colors.core.purple.100);
  --color-global-action-primary-disabled: theme(colors.core.grey.40);
  --color-global-action-danger: theme(colors.core.red.90);
  --color-global-action-danger-hover: theme(colors.core.red.80);
  --color-global-action-danger-pressed: theme(colors.core.red.100);
  --color-global-action-danger-disabled: theme(colors.core.grey.40);
  --color-global-action-secondary: theme(colors.white);
  --color-global-action-secondary-hover: theme(colors.core.grey.30);
  --color-global-action-secondary-pressed: theme(colors.core.grey.40);
  --color-global-action-secondary-disabled: theme(colors.core.grey.40);
  --color-global-action-tertiary-disabled: theme(colors.core.grey.40);
  --color-global-action-border-selected: theme(colors.core.grey.100);
  --color-global-action-border-disabled: theme(colors.core.grey.60);
  --color-global-action-border-hover: theme(colors.core.grey.90);
  --color-global-action-add-new: theme(colors.core.grey_variant.120);
  --color-global-action-add-new-hover: theme(colors.core.grey_variant.110);
  /* interactive */
  --color-global-interactive-default: theme(colors.core.purple.90);
  --color-global-focus: theme(colors.core.blue.70);
  /* tables */
  --color-global-row-border: theme(colors.core.grey.30);
  --color-global-row-border-selected: theme(colors.core.grey.40);
  --color-global-row-hover: theme(colors.core.grey.20);
  --color-global-row-footer: theme(colors.core.purple.10);

  /* tags */
  --color-global-tag-background-default: theme(colors.core.grey.30);
  /* timelines */
  --color-global-timeline-line-success: theme(colors.core.teal.110);
  --color-global-timeline-line-error: theme(colors.core.red.110);
  --color-global-timeline-line-warning: theme(colors.core.orange.110);
  --color-global-timeline-line-neutral: theme(colors.core.grey.30);
  /* navigation */
  --color-global-navigation-background-selected: theme(colors.core.purple.20);
  --color-global-navigation-background-hover: theme(colors.core.purple.10);
  --color-global-navigation-tab-selected: theme(colors.core.purple.90);
  /* toggle button */
  --color-global-toggle-background-default: theme(colors.core.grey.40);
  --color-global-toggle-background-selected: theme(colors.core.green.50);
  /* project menu */
  --color-global-project-background-default: theme(colors.core.grey.20);
  --color-global-project-list-background: theme(colors.core.grey.10);
  --color-global-project-menu-hover: theme(colors.core.grey.30);
  --color-global-project-menu-merchant-logo-background: theme(colors.core.grey.40);
  /* filters */
  --color-global-filter-background-1: theme(colors.white);
  --color-global-filter-background-2: theme(colors.core.grey.10);
  --color-global-filter-background-3: theme(colors.core.grey.20);
  --color-global-filter-border-1: theme(colors.core.grey.40);
  --color-global-filter-border-2: theme(colors.core.grey.30);
  --color-global-filter-border-3: theme(colors.core.grey.30);
  /* tooltip */
  --color-global-tooltip-background-default: theme(colors.core.grey.30);
  /* drag and drop */
  --color-global-dragndrop-background: theme(colors.core.grey.10);
  --color-global-dragndrop-background-active: theme(colors.core.purple.10);
  --color-global-dragndrop-icon-background: theme(colors.core.purple.20);
  --color-global-dragndrop-icon-error: theme(colors.core.red.70);
  --color-global-dragndrop-icon-background-error: theme(colors.core.red.20);
  --color-global-dragndrop-border-error: theme(colors.core.red.40);
  /* progress bar */
  --color-global-progress-bar-progression: theme(colors.core.green.40);
  /* routing card*/
  --color-global-routing-button-background: theme(colors.core.grey.30);
  --color-global-card-authorize-background: theme(colors.core.green.10);
  --color-global-card-authorize-border: theme(colors.core.green.20);
  --color-global-card-authenticate-background: theme(colors.core.orange.10);
  --color-global-card-authenticate-border: theme(colors.core.orange.20);
  --color-global-card-overlay-background: theme(colors.core.grey.50);
  --color-global-card-overlay-border: theme(colors.core.grey.70);
  --color-global-card-error-background: theme(colors.core.red.20);
  --color-global-card-error-border: theme(colors.core.red.100);
  --color-global-card-generic-background: theme(colors.core.grey.10);
  --color-global-card-generic-border: theme(colors.core.grey.30);
  /* routing pill */
  --color-global-pill-authorize-background: theme(colors.core.green.20);
  --color-global-pill-authenticate-background: theme(colors.core.orange.20);
  --color-global-pill-error-background: theme(colors.core.red.30);
  --color-global-pill-default-background: theme(colors.core.grey.30);
  /* routing card title */
  --color-global-card-title: theme(colors.core.grey.100);
  /* tags */
  --color-global-routing-tag: theme(colors.core.grey.20);
  /* login */
  --color-login-surface: theme(colors.designTokens.light.surfaceLogin);
  --color-login-title: theme(colors.designTokens.light.titleLogin);
  /* design tokens */
  --color-global-surface-tokens-neutral: theme(colors.designTokens.light.surfaceNeutral);
  --color-global-surface-tokens-success: theme(colors.designTokens.light.surfaceSuccess);
  --color-global-surface-tokens-warning: theme(colors.designTokens.light.surfaceWarning);
  --color-global-surface-tokens-error: theme(colors.designTokens.light.surfaceError);
  --color-global-surface-tokens-informative: theme(colors.designTokens.light.surfaceInformative);
}

/* Dark theme */
.dark {
  /* surface */
  --color-global-surface-0: theme(colors.core.grey_variant.120);
  --color-global-surface-1: 19 21 38;
  --color-global-surface-neutral: theme(colors.core.grey_variant.110);
  --color-global-surface-success: theme(colors.core.green.100);
  --color-global-surface-success-2: theme(colors.core.green.100);
  --color-global-surface-error: theme(colors.core.red.80);
  --color-global-surface-warning: theme(colors.core.orange.90);
  --color-global-surface-warning-2: theme(colors.core.orange.90);
  --color-global-surface-shadow-root: theme(colors.core.grey_variant.90);
  --color-global-surface-default: theme(colors.core.grey_variant.90);
  /* text */
  --color-global-text-primary: theme(colors.core.grey.10);
  --color-global-text-secondary: theme(colors.core.grey.20);
  --color-global-text-tertiary: theme(colors.core.grey.30);
  --color-global-text-muted: theme(colors.core.grey_variant.60);
  --color-global-text-disabled: theme(colors.core.grey_variant.70);
  --color-global-text-on-color: theme(colors.core.grey_variant.10);
  --color-global-text-success: theme(colors.core.green.20);
  --color-global-text-error: theme(colors.core.red.20);
  --color-global-text-warning: theme(colors.core.orange.20);
  --color-global-text-danger: theme(colors.core.red.20);
  --color-global-text-white: theme(colors.white);
  --color-global-text-contrast: theme(colors.core.grey.120);

  /* borders and dividers*/
  --color-global-border-subtle: theme(colors.core.grey_variant.90);
  --color-global-border-default: theme(colors.core.grey_variant.50);
  --color-global-divider: theme(colors.core.grey_variant.100);
  --color-contrast-divider: theme(colors.white);
  --color-global-drag-and-drop-divider: theme(colors.core.yellow.40);
  /* action */
  --color-global-action-primary: theme(colors.core.purple.60);
  --color-global-action-primary-hover: theme(colors.core.purple.50);
  --color-global-action-primary-pressed: theme(colors.core.purple.70);
  --color-global-action-primary-disabled: theme(colors.core.grey_variant.90);
  --color-global-action-danger: theme(colors.core.red.60);
  --color-global-action-danger-hover: theme(colors.core.red.50);
  --color-global-action-danger-pressed: theme(colors.core.red.70);
  --color-global-action-danger-disabled: theme(colors.core.grey_variant.90);
  --color-global-action-secondary: theme(colors.core.grey_variant.120);
  --color-global-action-secondary-hover: theme(colors.core.grey_variant.100);
  --color-global-action-secondary-pressed: theme(colors.core.grey_variant.110);
  --color-global-action-secondary-disabled: theme(colors.core.grey_variant.90);
  --color-global-action-tertiary-disabled: theme(colors.core.grey_variant.90);
  --color-global-action-border-selected: theme(colors.core.grey_variant.30);
  --color-global-action-border-hover: theme(colors.core.grey_variant.40);
  --color-global-action-border-disabled: theme(colors.core.grey_variant.80);
  --color-global-action-add-new: theme(colors.core.grey.30);
  --color-global-action-add-new-hover: theme(colors.core.grey_variant.20);
  /* interactive */
  --color-global-interactive-default: theme(colors.core.purple.50);
  --color-global-focus: theme(colors.core.yellow.50);
  /* tables */
  --color-global-row-border: theme(colors.core.grey_variant.100);
  --color-global-row-border-selected: theme(colors.core.grey_variant.90);
  --color-global-row-hover: theme(colors.core.grey_variant.100);
  --color-global-row-footer: theme(colors.core.grey_variant.100);
  /* tags */
  --color-global-tag-background-default: theme(colors.core.grey_variant.90);
  /* timelines */
  --color-global-timeline-line-success: theme(colors.core.teal.40);
  --color-global-timeline-line-error: theme(colors.core.red.40);
  --color-global-timeline-line-warning: theme(colors.core.orange.40);
  --color-global-timeline-line-neutral: theme(colors.core.grey_variant.100);
  /* navigation */
  --color-global-navigation-background-selected: theme(colors.core.grey_variant.90);
  --color-global-navigation-background-hover: theme(colors.core.grey_variant.100);
  --color-global-navigation-tab-selected: theme(colors.core.grey.30);
  /* toggle button */
  --color-global-toggle-background-default: theme(colors.core.grey_variant.70);
  --color-global-toggle-background-selected: theme(colors.core.green.70);
  /* project menu */
  --color-global-project-background-default: theme(colors.core.grey_variant.100);
  --color-global-project-list-background: theme(colors.core.grey_variant.110);
  --color-global-project-menu-hover: theme(colors.core.grey_variant.90);
  --color-global-project-menu-merchant-logo-background: theme(colors.core.grey_variant.80);
  /* filters */
  --color-global-filter-background-1: theme(colors.core.grey_variant.100);
  --color-global-filter-background-2: theme(colors.core.grey_variant.110);
  --color-global-filter-background-3: theme(colors.core.grey_variant.120);
  --color-global-filter-border-1: theme(colors.core.grey_variant.80);
  --color-global-filter-border-2: theme(colors.core.grey_variant.90);
  --color-global-filter-border-3: theme(colors.core.grey_variant.100);
  /* tooltip */
  --color-global-tooltip-background-default: theme(colors.core.grey_variant.90);
  /* drag and drop */
  --color-global-dragndrop-background: theme(colors.core.grey_variant.80);
  --color-global-dragndrop-background-active: theme(colors.core.grey_variant.90);
  --color-global-dragndrop-icon-background: theme(colors.core.grey_variant.100);
  --color-global-dragndrop-icon-error: theme(colors.core.red.70);
  --color-global-dragndrop-icon-background-error: theme(colors.core.red.20);
  --color-global-dragndrop-border-error: theme(colors.core.red.40);
  /* progress bar */
  --color-global-progress-bar-progression: theme(colors.core.green.60);
  /* routing card */
  --color-global-routing-button-background: theme(colors.core.grey_variant.100);
  --color-global-card-authorize-background: theme(colors.core.green.120);
  --color-global-card-authorize-border: theme(colors.core.green.110);
  --color-global-card-authenticate-background: theme(colors.core.orange.110);
  --color-global-card-authenticate-border: theme(colors.core.orange.100);
  --color-global-card-overlay-background: theme(colors.core.grey_variant.70);
  --color-global-card-overlay-border: theme(colors.core.grey_variant.60);
  --color-global-card-error-background: theme(colors.core.red.80);
  --color-global-card-error-border: theme(colors.core.red.40);
  --color-global-card-generic-background: theme(colors.core.grey_variant.110);
  --color-global-card-generic-border: theme(colors.core.grey_variant.100);
  /* rouitng card pill */
  --color-global-pill-authorize-background: theme(colors.core.green.100);
  --color-global-pill-authenticate-background: theme(colors.core.orange.100);
  --color-global-pill-error-background: theme(colors.core.red.100);
  --color-global-pill-default-background: theme(colors.core.grey_variant.100);
  /* routing card title */
  --color-global-card-title: theme(colors.core.grey_variant.30);
  /* tags */
  --color-global-routing-tag: theme(colors.core.grey_variant.100);
  /* login */
  --color-login-surface: theme(colors.designTokens.dark.surfaceLogin);
  --color-login-title: theme(colors.designTokens.dark.titleLogin);
  /* design tokens */
  --color-global-surface-tokens-neutral: theme(colors.designTokens.dark.surfaceNeutral);
  --color-global-surface-tokens-success: theme(colors.designTokens.dark.surfaceSuccess);
  --color-global-surface-tokens-warning: theme(colors.designTokens.dark.surfaceWarning);
  --color-global-surface-tokens-error: theme(colors.designTokens.dark.surfaceError);
  --color-global-surface-tokens-informative: theme(colors.designTokens.dark.surfaceInformative);
}

/*
  for the sandbox theming, we need to ensure that all the tokens present in `.sandbox` is in `.dark.sandbox` and vice versa
  if not, you will ends up with token you potentially don't want
*/
.sandbox {
  /* action */
  --color-global-action-primary: theme(colors.core.orange.90);
  --color-global-action-primary-hover: theme(colors.core.orange.80);
  --color-global-action-primary-pressed: theme(colors.core.orange.100);
  /* interactive */
  --color-global-interactive-default: theme(colors.core.orange.90);
  /* navigation */
  --color-global-navigation-background-selected: theme(colors.core.orange.20);
  --color-global-navigation-background-hover: theme(colors.core.orange.10);
  --color-global-navigation-tab-selected: theme(colors.core.orange.90);
  /* drag and drop */
  --color-global-dragndrop-background-active: theme(colors.core.orange.10);
  --color-global-dragndrop-icon-background: theme(colors.core.orange.20);
}

.dark.sandbox {
  /* action */
  --color-global-action-primary: theme(colors.core.orange.60);
  --color-global-action-primary-hover: theme(colors.core.orange.50);
  --color-global-action-primary-pressed: theme(colors.core.orange.70);
  /* interactive */
  --color-global-interactive-default: theme(colors.core.orange.50);
  /* navigation */
  --color-global-navigation-background-selected: theme(colors.core.orange.100);
  --color-global-navigation-background-hover: theme(colors.core.orange.110);
  --color-global-navigation-tab-selected: theme(colors.core.orange.90);
  /* drag and drop */
  --color-global-dragndrop-background-active: theme(colors.core.orange.110);
  --color-global-dragndrop-icon-background: theme(colors.core.orange.100);
}
