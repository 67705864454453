.react-aria-DatePicker,
.react-aria-CustomInput {
  @apply flex min-h-[2.625rem] !cursor-pointer !rounded-lg !border !border-border-subtle !bg-surface-0 !px-3 !py-2 !text-text-secondary hover:!border-action-border-hover;
}
.react-aria-DatePicker .react-aria-DateInput,
.react-aria-CustomInput .react-aria-DateInput {
  @apply flex flex-1 items-center;
}
.react-aria-DatePicker .react-aria-Group {
  @apply flex flex-1;
}
.react-aria-RangeCalendar,
.react-aria-Calendar {
  @apply !cursor-pointer !rounded-lg !border !border-border-subtle !bg-surface-0 !px-3 !py-[0.625rem] !text-text-secondary;

  header {
    @apply mx-1 mb-4 mt-0 flex items-center;
  }
}
.react-aria-RangeCalendar .react-aria-CalendarCell,
.react-aria-Calendar .react-aria-CalendarCell {
  @apply mb-1 w-[2.25rem] text-center leading-[2.25rem] outline-none;
}
.react-aria-CalendarCell[data-outside-month] {
  @apply hidden;
}
.react-aria-CalendarGridBody td,
.react-aria-CalendarHeaderCell,
.react-aria-CalendarCell {
  @apply border-none;
}
.react-aria-CalendarCell {
  @apply cursor-pointer;
}
.react-aria-CalendarCell:hover {
  @apply bg-action-primary-hover text-text-on-color;
}
.react-aria-RangeCalendar .react-aria-CalendarCell[data-selected] {
  @apply bg-action-primary text-text-on-color;
}
.react-aria-Heading {
  @apply m-0 flex-1 text-center text-subtitle-medium;
}
.react-aria-DateRangePicker [slot="start"] + span {
  @apply px-1 py-0;
}
.react-aria-DateInput {
  @apply mr-4 flex-1;
}
.react-aria-CalendarCell[data-selected] {
  @apply bg-action-primary text-text-on-color;
}
