.react-select__value-container {
  @apply !p-0;
}

.react-select__input-container {
  @apply !m-0 !p-0;
}

.react-select__single-value {
  @apply !m-0 !text-text-tertiary;
}

.react-select__input {
  @apply !text-text-tertiary;
}

.react-select__multi-value__label {
  @apply !py-0.5 !pl-2 !leading-tag !text-text-tertiary;
}

.react-select__multi-value {
  @apply !mr-2 !bg-tag-background-default;
}

.react-select__multi-value__remove {
  @apply !bg-tag-background-default !text-text-tertiary;
}

.react-select__menu-portal {
  @apply !z-50;
}

.react-select__indicators {
  @apply !ml-1 !gap-1.5;
}

.react-select__indicator {
  @apply !p-0;
}

.react-select__indicator-separator {
  @apply !hidden;
}

.react-select__control {
  @apply !min-h-[2.625rem] !cursor-pointer !rounded-lg !border !border-border-subtle !bg-surface-0 !px-3 !py-2 !text-text-secondary hover:!border-action-border-hover;
}

.react-select__control--is-disabled {
  @apply !border-action-border-disabled !bg-action-tertiary-disabled !text-text-disabled;
}

.react-select__menu {
  @apply !pointer-events-auto !max-h-[20rem] !overflow-auto !rounded-lg !border !border-border-subtle !bg-surface-0;
}

.react-select__option {
  @apply !cursor-pointer !py-3 !pl-3;
}

.react-select__option--is-selected {
  @apply !cursor-default !text-text-disabled;
}

.react-select__no-options-message {
  @apply !p-3;
}

.react-select__dropdown-indicator {
  @apply !text-text-secondary;
}

.error {
  .react-select__control {
    @apply !border-border-error !bg-input-background-error;
  }
}
