:root {
  --color-brand-brand-blue: #02062bff;
  --opacity-dark-2: #12131405;
  --opacity-dark-4: #1213140a;
  --opacity-dark-6: #1213140f;
  --opacity-dark-8: #12131414;
  --opacity-dark-12: #1213141f;
  --opacity-dark-16: #21222229;
  --opacity-dark-20: #12131433;
  --opacity-dark-24: #1213143d;
  --opacity-dark-28: #12131447;
  --opacity-dark-32: #12131452;
  --opacity-dark-40: #12131466;
  --opacity-dark-48: #1213147a;
  --opacity-dark-64: #121314a3;
  --opacity-dark-80: #121314cc;
  --opacity-dark-88: #121314e0;
  --opacity-dark-92: #121314eb;
  --opacity-dark-96: #121314f5;
  --opacity-dark-98: #121314fa;
  --opacity-light-2: #f6f8fb05;
  --opacity-light-4: #f6f8fb0a;
  --opacity-light-6: #f6f8fb0f;
  --opacity-light-8: #f6f8fb14;
  --opacity-light-12: #f6f8fb1f;
  --opacity-light-16: #f6f8fb29;
  --opacity-light-20: #f6f8fb33;
  --opacity-light-24: #f6f8fb3d;
  --opacity-light-28: #f6f8fb47;
  --opacity-light-32: #f6f8fb52;
  --opacity-light-40: #f6f8fb66;
  --opacity-light-48: #f6f8fb7a;
  --opacity-light-64: #f6f8fba3;
  --opacity-light-80: #f6f8fbcc;
  --opacity-light-88: #f6f8fbe0;
  --opacity-light-92: #f6f8fbeb;
  --opacity-light-96: #f6f8fbf5;
  --opacity-light-98: #f6f8fbfa;
  --color-brand-brand-yellow: #ffff00ff;
  --color-brand-gun-metal: #9fbfc3ff;
  --color-grey-white: #ffffffff;
  --color-grey-black: #000000ff;
  --color-grey-grey-1: #fcfcfcff;
  --color-grey-grey-2: #fcfcfcff;
  --color-grey-grey-3: #f1f1f2ff;
  --color-grey-grey-4: #e6e9edff;
  --color-grey-grey-5: #e2e2e2ff;
  --color-grey-grey-6: #c0c3c8ff;
  --color-grey-grey-7: #a7a9afff;
  --color-grey-grey-8: #8a8d93ff;
  --color-grey-grey-9: #707378ff;
  --color-grey-grey-10: #585a5fff;
  --color-grey-grey-11: #3d4149ff;
  --color-grey-grey-12: #2e3137ff;
  --color-grey-grey-13: #26292fff;
  --color-grey-grey-14: #1d2026ff;
  --color-grey-grey-15: #17191eff;
  --color-grey-grey-16: #080c17ff;
  --color-green-green-1: #eefeedff;
  --color-green-green-2: #e4f9e3ff;
  --color-green-green-3: #cff3cdff;
  --color-green-green-4: #d1e8caff;
  --color-green-green-5: #bad8b1ff;
  --color-green-green-6: #28de6bff;
  --color-green-green-7: #1dd163ff;
  --color-green-green-8: #1abe5aff;
  --color-green-green-9: #16ac50ff;
  --color-green-green-10: #139947ff;
  --color-green-green-11: #0c7434ff;
  --color-green-green-12: #0e4d29ff;
  --color-green-green-13: #0a4322ff;
  --color-green-green-14: #143225ff;
  --color-green-green-15: #0d2219ff;
  --color-green-green-16: #0c2118ff;
  --color-orange-orange-1: #fff5eeff;
  --color-orange-orange-2: #fcf1ecff;
  --color-orange-orange-3: #f8e1cfff;
  --color-orange-orange-4: #f8d7bfff;
  --color-orange-orange-5: #f2c5adff;
  --color-orange-orange-6: #fbb691ff;
  --color-orange-orange-7: #fc9d69ff;
  --color-orange-orange-8: #fb833eff;
  --color-orange-orange-9: #f26913ff;
  --color-orange-orange-10: #da5e10ff;
  --color-orange-orange-11: #a64609ff;
  --color-orange-orange-12: #6f2d05ff;
  --color-orange-orange-13: #522306ff;
  --color-orange-orange-14: #411e07ff;
  --color-orange-orange-15: #341907ff;
  --color-orange-orange-16: #2f1504ff;
  --color-red-red-1: #fff2f2ff;
  --color-red-red-2: #fceeedff;
  --color-red-red-3: #fde3deff;
  --color-red-red-4: #f5d9d9ff;
  --color-red-red-5: #fbccccff;
  --color-red-red-6: #f7bcbcff;
  --color-red-red-7: #ff8888ff;
  --color-red-red-8: #ff7d6cff;
  --color-red-red-9: #ff4e4eff;
  --color-red-red-10: #f03030ff;
  --color-red-red-11: #be011bff;
  --color-red-red-12: #870010ff;
  --color-red-red-13: #630407ff;
  --color-red-red-14: #511511ff;
  --color-red-red-15: #3d0d04ff;
  --color-red-red-16: #230d04ff;
  --color-blue-blue-1: #f8f5ffff;
  --color-blue-blue-2: #edeefaff;
  --color-blue-blue-3: #eaeefcff;
  --color-blue-blue-4: #e0e7fbff;
  --color-blue-blue-5: #d1dcffff;
  --color-blue-blue-6: #b1c7fdff;
  --color-blue-blue-7: #99b6ffff;
  --color-blue-blue-8: #84a4ffff;
  --color-blue-blue-9: #7492ffff;
  --color-blue-blue-10: #697cffff;
  --color-blue-blue-11: #374affff;
  --color-blue-blue-12: #002fc6ff;
  --color-blue-blue-13: #082396ff;
  --color-blue-blue-14: #1d2774ff;
  --color-blue-blue-15: #0d1c7aff;
  --color-blue-blue-16: #161945ff;
  --color-purple-purple-1: #faf3ffff;
  --color-purple-purple-2: #f5f0faff;
  --color-purple-purple-3: #f3ebfaff;
  --color-purple-purple-4: #efe2f9ff;
  --color-purple-purple-5: #e8d6f8ff;
  --color-purple-purple-6: #dabaf8ff;
  --color-purple-purple-7: #cfa5f8ff;
  --color-purple-purple-8: #c391f6ff;
  --color-purple-purple-9: #b67cf5ff;
  --color-purple-purple-10: #a765f3ff;
  --color-purple-purple-11: #8835f0ff;
  --color-purple-purple-12: #5a12b3ff;
  --color-purple-purple-13: #440e89ff;
  --color-purple-purple-14: #3f1b6cff;
  --color-purple-purple-15: #2e0a5cff;
  --color-purple-purple-16: #1b0e2cff;
  --color-gun-metal-gun-metal-1: #f7fafaff;
  --color-yellow-yellow-1: #ffffebff;
  --color-yellow-yellow-2: #ffffcfff;
  --color-yellow-yellow-3: #ffffb5ff;
  --color-yellow-yellow-4: #ffff97ff;
  --color-yellow-yellow-5: #ffff00ff;
  --color-yellow-yellow-6: #d8dc05ff;
  --color-yellow-yellow-7: #d8dc05ff;
  --color-yellow-yellow-8: #b2b602ff;
  --color-yellow-yellow-9: #979b01ff;
  --color-yellow-yellow-10: #7d8001ff;
  --color-yellow-yellow-11: #606301ff;
  --color-yellow-yellow-12: #434501ff;
  --color-yellow-yellow-13: #393a06ff;
  --color-yellow-yellow-14: #242400ff;
  --color-yellow-yellow-15: #1c1a00ff;
  --color-yellow-yellow-16: #111000ff;
  --color-gun-metal-gun-metal-2: #eef5f6ff;
  --color-gun-metal-gun-metal-3: #e5f0f1ff;
  --color-gun-metal-gun-metal-4: #dcebedff;
  --color-gun-metal-gun-metal-5: #d2e7e9ff;
  --color-gun-metal-gun-metal-6: #b1d2d7ff;
  --color-gun-metal-gun-metal-7: #a7c8ccff;
  --color-gun-metal-gun-metal-8: #9fbfc3ff;
  --color-gun-metal-gun-metal-9: #819b9eff;
  --color-gun-metal-gun-metal-10: #6a8083ff;
  --color-gun-metal-gun-metal-11: #516365ff;
  --color-gun-metal-gun-metal-12: #384546ff;
  --color-gun-metal-gun-metal-13: #252e2fff;
  --color-gun-metal-gun-metal-14: #1d2425ff;
  --color-gun-metal-gun-metal-15: #151b1bff;
  --color-gun-metal-gun-metal-16: #0d1011ff;
  --color-text-general-primary: var(--color-grey-black);
  --color-text-general-secondary: var(--color-grey-grey-10);
  --color-text-general-tertiary: var(--color-grey-grey-8);
  --color-text-general-disabled: var(--color-grey-grey-6);
  --color-text-general-inverse: var(--color-grey-white);
  --color-text-general-white: var(--color-grey-white);
  --color-text-general-action: var(--color-blue-blue-11);
  --color-text-general-error: var(--color-red-red-11);
  --color-text-general-warning: var(--color-orange-orange-10);
  --color-text-general-positive: var(--color-green-green-10);
  --color-text-onbutton-disabled: var(--color-grey-grey-6);
  --color-text-onbutton-inverse: var(--color-grey-white);
  --color-text-onbutton-primary-default: var(--color-grey-white);
  --color-text-onbutton-primary-hover-active: var(--color-grey-white);
  --color-text-onbutton-primary-pressed: var(--color-grey-white);
  --color-text-onbutton-secondary-default: var(--color-grey-black);
  --color-text-onbutton-secondary-hover-active: var(--color-grey-black);
  --color-text-onbutton-secondary-pressed: var(--color-grey-black);
  --color-text-onbutton-ghost-default: var(--color-grey-grey-10);
  --color-text-onbutton-ghost-hover-active: var(--color-grey-black);
  --color-text-onbutton-ghost-pressed: var(--color-grey-black);
  --color-text-onbutton-error-default: var(--color-grey-white);
  --color-text-onbutton-error-hover-active: var(--color-grey-white);
  --color-text-onbutton-error-pressed: var(--color-grey-white);
  --color-text-onbutton-positive-default: var(--color-grey-white);
  --color-text-onbutton-positive-hover-active: var(--color-grey-white);
  --color-text-onbutton-positive-pressed: var(--color-grey-white);
  --color-text-onbutton-informative-default: var(--color-blue-blue-15);
  --color-text-onbutton-informative-hover-active: var(--color-blue-blue-15);
  --color-text-onbutton-informative-pressed: var(--color-grey-white);
  --color-text-ondropdownmenu-placeholder: var(--color-text-general-secondary);
  --color-text-onfilter-primary: var(--color-text-general-primary);
  --color-text-onfilter-secondary: var(--color-text-general-secondary);
  --color-text-onfilter-error: var(--color-red-red-13);
  --color-text-oninput-placeholder: var(--color-grey-grey-9);
  --color-text-oninput-active: var(--color-text-general-primary);
  --color-text-oninput-label: var(--color-text-general-primary);
  --color-text-oninput-error: var(--color-red-red-11);
  --color-text-oninput-helper: var(--color-text-general-secondary);
  --color-text-oninput-disabled: var(--color-grey-grey-7);
  --color-text-ontip-default: var(--color-grey-white);
  --color-text-ontip-error: var(--color-red-red-13);
  --color-text-ontip-informative: var(--color-blue-blue-15);
  --color-text-ontip-positive: var(--color-green-green-13);
  --color-text-ontip-sandbox: var(--color-yellow-yellow-14);
  --color-text-ontip-error-emphasized: var(--color-grey-white);
  --color-text-ontab-default: var(--color-grey-grey-9);
  --color-text-ontab-hover: var(--color-grey-grey-11);
  --color-text-ontab-active: var(--color-grey-black);
  --color-text-ontag-neutral: var(--color-grey-black);
  --color-text-ontag-success: var(--color-green-green-14);
  --color-text-ontag-warning: var(--color-orange-orange-13);
  --color-text-ontag-error: var(--color-red-red-13);
  --color-text-ontag-informative: var(--color-blue-blue-14);
  --color-text-ontag-purple: var(--color-purple-purple-14);
  --color-text-ontag-contrast: var(--color-grey-white);
  --color-text-ontag-disabled: var(--color-grey-grey-7);
  --color-icon-general-primary: var(--color-grey-black);
  --color-icon-general-secondary: var(--color-grey-grey-9);
  --color-icon-general-tertiary: var(--color-grey-grey-8);
  --color-icon-general-disabled: var(--color-grey-grey-6);
  --color-icon-general-inverse: var(--color-grey-white);
  --color-icon-general-error: var(--color-red-red-11);
  --color-icon-general-positive: var(--color-green-green-10);
  --color-icon-general-action: var(--color-blue-blue-11);
  --color-icon-general-warning: var(--color-orange-orange-10);
  --color-icon-onbutton-disabled: var(--color-grey-grey-6);
  --color-icon-onbutton-inverse: var(--color-grey-white);
  --color-icon-onbutton-primary-default: var(--color-grey-white);
  --color-icon-onbutton-primary-hover-active: var(--color-grey-white);
  --color-icon-onbutton-primary-pressed: var(--color-grey-white);
  --color-icon-onbutton-secondary-default: var(--color-grey-black);
  --color-icon-onbutton-secondary-hover-active: var(--color-grey-black);
  --color-icon-onbutton-secondary-pressed: var(--color-grey-black);
  --color-icon-onbutton-ghost-default: var(--color-grey-grey-10);
  --color-icon-onbutton-ghost-hover-active: var(--color-grey-black);
  --color-icon-onbutton-ghost-pressed: var(--color-grey-black);
  --color-icon-onbutton-error-default: var(--color-grey-white);
  --color-icon-onbutton-error-hover-active: var(--color-grey-white);
  --color-icon-onbutton-error-pressed: var(--color-grey-white);
  --color-icon-onbutton-positive-default: var(--color-grey-white);
  --color-icon-onbutton-positive-hover-active: var(--color-grey-white);
  --color-icon-onbutton-positive-presseed: var(--color-grey-white);
  --color-icon-onbutton-informative-default: var(--color-blue-blue-15);
  --color-icon-onbutton-informative-hover-active: var(--color-blue-blue-15);
  --color-icon-onbutton-informative-pressed: var(--color-grey-white);
  --color-icon-onfilter-primary: var(--color-icon-general-primary);
  --color-icon-onfilter-secondary: var(--color-icon-general-secondary);
  --color-icon-onfilter-error: var(--color-red-red-13);
  --color-icon-oninput-default: var(--color-grey-black);
  --color-icon-oninput-error: var(--color-red-red-11);
  --color-icon-oninput-disabled: var(--color-grey-grey-7);
  --color-icon-oninput-default-filled: var(--color-grey-grey-10);
  --color-icon-oninput-active: var(--color-grey-black);
  --color-icon-oninput-placeholder: var(--color-grey-grey-9);
  --color-icon-onmainmenu-default: var(--color-grey-grey-9);
  --color-icon-onmainmenu-hover: var(--color-grey-grey-9);
  --color-icon-onmainmenu-active: var(--color-grey-grey-14);
  --color-icon-onmainmenu-logo: var(--color-grey-black);
  --color-icon-ontip-default: var(--color-grey-white);
  --color-icon-ontip-error: var(--color-red-red-13);
  --color-icon-ontip-informative: var(--color-blue-blue-15);
  --color-icon-ontip-positive: var(--color-green-green-13);
  --color-icon-ontip-sandbox: var(--color-yellow-yellow-14);
  --color-icon-ontip-error-emphasized: var(--color-grey-white);
  --color-icon-ontag-neutral: var(--color-grey-black);
  --color-icon-ontag-success: var(--color-green-green-14);
  --color-icon-ontag-warning: var(--color-orange-orange-13);
  --color-icon-ontag-error: var(--color-red-red-13);
  --color-icon-ontag-informative: var(--color-blue-blue-14);
  --color-icon-ontag-purple: var(--color-purple-purple-14);
  --color-icon-ontag-contrast: var(--color-grey-white);
  --color-icon-ontag-disabled: var(--color-grey-grey-7);
  --color-surface-background-bg-primary: var(--color-grey-grey-2);
  --color-surface-background-bg-secondary: var(--color-grey-grey-3);
  --color-surface-background-bg-tertiary: var(--color-grey-grey-4);
  --color-surface-background-bg-quarternary: var(--color-grey-grey-5);
  --color-surface-background-elevated: var(--color-grey-white);
  --color-surface-background-success: var(--color-green-green-8);
  --color-surface-background-success-subtle: var(--color-green-green-3);
  --color-surface-background-warning: var(--color-orange-orange-8);
  --color-surface-background-warning-subtle: var(--color-orange-orange-3);
  --color-surface-background-error: var(--color-red-red-9);
  --color-surface-background-error-subtle: var(--color-red-red-3);
  --color-surface-background-informative: var(--color-blue-blue-10);
  --color-surface-background-informative-subtle: var(--color-blue-blue-3);
  --color-surface-background-darkout-01: var(--opacity-dark-2);
  --color-surface-background-darkout-02: var(--opacity-dark-4);
  --color-surface-background-darkout-03: var(--opacity-dark-6);
  --color-surface-background-darkout-04: var(--opacity-dark-8);
  --color-surface-background-darkout-05: var(--opacity-dark-12);
  --color-surface-background-darkout-06: var(--opacity-dark-16);
  --color-surface-background-modal-darkout: var(--opacity-dark-48);
  --color-surface-button-disabled: var(--opacity-dark-4);
  --color-surface-counter-counter: var(--color-grey-grey-9);
  --color-surface-dropdown-menu-hover: var(--opacity-dark-6);
  --color-surface-dropdown-menu-active: var(--opacity-dark-4);
  --color-surface-dropdown-menu-active-hover: var(--opacity-dark-6);
  --color-surface-dropdown-menu-pressed: var(--opacity-dark-12);
  --color-surface-dropdown-menu-bg: var(--color-grey-grey-2);
  --color-surface-button-primary-default: var(--color-grey-black);
  --color-surface-button-primary-hover-active: var(--color-grey-grey-12);
  --color-surface-button-primary-pressed: var(--color-grey-grey-13);
  --color-surface-button-secondary-default: var(--opacity-dark-6);
  --color-surface-button-secondary-hover-active: var(--opacity-dark-12);
  --color-surface-button-secondary-pressed: var(--opacity-dark-16);
  --color-surface-button-ghost-hover-active: var(--opacity-dark-6);
  --color-surface-button-ghost-pressed: var(--opacity-dark-12);
  --color-surface-button-error-default: var(--color-red-red-11);
  --color-surface-button-error-hover-active: var(--color-red-red-12);
  --color-surface-button-error-pressed: var(--color-red-red-14);
  --color-surface-button-positive-default: var(--color-green-green-9);
  --color-surface-button-positive-hover-active: var(--color-green-green-11);
  --color-surface-button-positive-pressed: var(--color-green-green-12);
  --color-surface-button-informative-default: var(--color-blue-blue-4);
  --color-surface-button-informative-hover-active: var(--color-blue-blue-6);
  --color-surface-button-informative-pressed: var(--color-blue-blue-10);
  --color-surface-check-radio-default: var(--color-grey-white);
  --color-surface-check-radio-hover: var(--color-grey-white);
  --color-surface-check-radio-active: var(--color-grey-black);
  --color-surface-check-radio-focus-helper: var(--color-grey-white);
  --color-surface-check-radio-error: var(--color-red-red-3);
  --color-surface-check-radio-disabled: var(--color-grey-grey-3);
  --color-surface-filter-active-default: var(--color-text-general-white);
  --color-surface-filter-active-hover: var(--color-grey-grey-3);
  --color-surface-filter-active-pressed: var(--color-grey-grey-5);
  --color-surface-filter-error-default: var(--color-red-red-2);
  --color-surface-filter-error-hover: var(--color-red-red-4);
  --color-surface-filter-error-pressed: var(--color-red-red-5);
  --color-surface-input-default-filled: var(--opacity-dark-6);
  --color-surface-input-active: var(--color-grey-white);
  --color-surface-input-hover-filled: var(--opacity-dark-8);
  --color-surface-input-error: var(--color-red-red-2);
  --color-surface-input-disabled: var(--opacity-dark-4);
  --color-surface-main-menu-hover: var(--opacity-dark-6);
  --color-surface-main-menu-active: var(--opacity-dark-6);
  --color-surface-main-menu-bg: var(--color-grey-grey-2);
  --color-surface-modal-modal-bg: var(--color-grey-white);
  --color-surface-routing-card-true: var(--color-green-green-1);
  --color-surface-routing-card-ab-test: var(--color-blue-blue-1);
  --color-surface-routing-card-filter: var(--color-purple-purple-1);
  --color-surface-routing-card-parameter: var(--color-grey-white);
  --color-surface-routing-card-authentication: var(--color-orange-orange-1);
  --color-surface-routing-card-block: var(--color-red-red-1);
  --color-surface-routing-tag-group-true: var(--opacity-dark-2);
  --color-surface-routing-tag-group-ab-test: var(--opacity-dark-4);
  --color-surface-routing-tag-group-filter: var(--opacity-dark-2);
  --color-surface-routing-tag-group-parameter: var(--opacity-dark-4);
  --color-surface-routing-tag-group-authentication: var(--opacity-dark-2);
  --color-surface-routing-tag-group-block: var(--opacity-dark-2);
  --color-surface-routing-thumbnail-true: var(--color-green-green-3);
  --color-surface-routing-thumbnail-ab-test: var(--color-blue-blue-5);
  --color-surface-routing-thumbnail-filter: var(--color-purple-purple-5);
  --color-surface-routing-thumbnail-parameter: var(--color-grey-grey-3);
  --color-surface-routing-thumbnail-authentication: var(--color-orange-orange-3);
  --color-surface-routing-thumbnail-block: var(--color-red-red-4);
  --color-surface-scroll-bar-default: var(--opacity-dark-16);
  --color-surface-scroll-bar-hover-pressing: var(--opacity-dark-28);
  --color-surface-tab-hover: var(--opacity-dark-8);
  --color-surface-tab-active: var(--color-grey-white);
  --color-surface-tag-tag-neutral: var(--opacity-dark-6);
  --color-surface-toast-default: var(--color-grey-grey-15);
  --color-surface-toast-error: var(--color-red-red-3);
  --color-surface-toast-informative: var(--color-blue-blue-3);
  --color-surface-toast-positive: var(--color-green-green-2);
  --color-surface-toast-sandbox: var(--color-yellow-yellow-2);
  --color-surface-toast-error-emphasized: var(--color-red-red-10);
  --color-surface-toast-button-hover-ondefault: var(--opacity-light-12);
  --color-surface-toast-button-pressed-ondefault: var(--opacity-light-8);
  --color-surface-toast-button-hover-oncolor: var(--opacity-dark-6);
  --color-surface-toast-button-pressed-oncolor: var(--opacity-dark-8);
  --color-surface-tag-tag-success: var(--color-green-green-3);
  --color-surface-tag-tag-warning: var(--color-orange-orange-3);
  --color-surface-tag-tag-error: var(--color-red-red-3);
  --color-surface-tag-tag-informative: var(--color-blue-blue-4);
  --color-surface-tag-tag-purple: var(--color-purple-purple-5);
  --color-surface-tag-tag-contrast: var(--color-grey-black);
  --color-surface-tag-tag-button-hover: var(--opacity-dark-6);
  --color-surface-tag-tag-button-pressed: var(--opacity-dark-8);
  --color-border-general-border-01: var(--opacity-dark-6);
  --color-border-input-default: var(--opacity-dark-12);
  --color-border-input-hover: var(--opacity-dark-24);
  --color-border-input-active: var(--color-grey-black);
  --color-border-input-error: var(--color-red-red-11);
  --color-border-input-disabled: var(--color-grey-grey-6);
  --color-border-general-border-02: var(--opacity-dark-8);
  --color-border-general-border-03: var(--opacity-dark-12);
  --color-border-general-border-04: var(--opacity-dark-16);
  --color-border-general-border-focus: var(--opacity-dark-32);
  --color-border-general-border-contrast: var(--color-grey-black);
  --color-border-general-border-error: var(--color-red-red-10);
  --color-border-button-focus-helper: var(--color-grey-white);
  --color-border-button-onprimary: var(--opacity-light-16);
  --color-border-button-onsecondary: var(--opacity-dark-6);
  --color-border-button-toastbuttononcolor: var(--opacity-dark-4);
  --color-border-button-toastbuttonondefault: var(--opacity-light-12);
  --color-border-table-default: var(--color-grey-grey-3);
  --color-border-table-header: var(--color-grey-grey-4);
  --color-border-routing-connection-line: var(--opacity-dark-4);
  --color-border-routing-border-true: var(--color-green-green-4);
  --color-border-routing-border-parameter: var(--color-grey-grey-4);
  --color-border-routing-border-filter: var(--color-purple-purple-5);
  --color-border-routing-border-authentication: var(--color-orange-orange-4);
  --color-border-routing-border-abtest: var(--color-blue-blue-5);
  --color-border-routing-border-block: var(--color-red-red-5);
  --color-border-check-radio-default: var(--color-grey-grey-6);
  --color-border-check-radio-hover: var(--color-grey-grey-9);
  --color-border-check-radio-focus: var(--color-blue-blue-11);
  --color-border-check-radio-focus-helper: var(--color-grey-white);
  --color-border-check-radio-disabled: var(--color-grey-grey-6);
  --color-border-check-radio-error: var(--color-red-red-10);
  --color-surface-toggle-default: var(--opacity-dark-16);
  --color-surface-toggle-active: var(--color-grey-black);
  --color-icon-check-radio-active: var(--color-grey-white);
  --color-surface-toggle-disabled: var(--opacity-dark-8);
  --color-icon-check-radio-disabled: var(--color-grey-grey-6);
  --color-surface-toggle-knob-default: var(--color-grey-white);
  --color-surface-toggle-knob-active: var(--color-grey-white);
  --color-surface-toggle-knob-disabled: var(--opacity-dark-12);
  --shadow-color-shadow-01: var(--opacity-dark-2);
  --shadow-color-shadow-015: var(--opacity-dark-2);
  --shadow-color-shadow-02: var(--opacity-dark-4);
  --shadow-color-shadow-04: var(--opacity-dark-4);
  --shadow-color-shadow-06: var(--opacity-dark-4);
  --shadow-color-shadow-08: var(--opacity-dark-6);
  --shadow-color-shadow-12: var(--opacity-dark-6);
  --shadow-color-shadow-16: var(--opacity-dark-6);
  --shadow-color-shadow-20: var(--opacity-dark-6);
  --shadow-color-shadow-32: var(--opacity-dark-12);
  --space-0: var(--numbers-0);
  --space-2: var(--numbers-2);
  --space-4: var(--numbers-4);
  --space-6: var(--numbers-6);
  --space-8: var(--numbers-8);
  --space-12: var(--numbers-12);
  --space-16: var(--numbers-16);
  --space-20: var(--numbers-20);
  --space-24: var(--numbers-24);
  --space-28: var(--numbers-28);
  --space-32: var(--numbers-32);
  --space-40: var(--numbers-40);
  --space-48: var(--numbers-48);
  --space-56: var(--numbers-56);
  --space-64: var(--numbers-64);
  --space-72: var(--numbers-72);
  --space-80: var(--numbers-80);
  --space-96: var(--numbers-96);
  --space-128: var(--numbers-128);
  --width-2: var(--numbers-2);
  --width-4: var(--numbers-4);
  --width-6: var(--numbers-6);
  --width-8: var(--numbers-8);
  --width-12: var(--numbers-12);
  --width-16: var(--numbers-16);
  --width-20: var(--numbers-20);
  --width-24: var(--numbers-24);
  --width-28: var(--numbers-28);
  --width-32: var(--numbers-32);
  --width-40: var(--numbers-40);
  --width-48: var(--numbers-48);
  --width-56: var(--numbers-56);
  --width-64: var(--numbers-64);
  --width-72: var(--numbers-72);
  --width-80: var(--numbers-80);
  --width-96: var(--numbers-96);
  --width-128: var(--numbers-128);
  --width-160: var(--numbers-160);
  --width-180: var(--numbers-180);
  --width-200: var(--numbers-200);
  --height-2: var(--numbers-2);
  --height-4: var(--numbers-4);
  --height-6: var(--numbers-6);
  --height-8: var(--numbers-8);
  --height-12: var(--numbers-12);
  --height-16: var(--numbers-16);
  --height-20: var(--numbers-20);
  --height-24: var(--numbers-24);
  --height-28: var(--numbers-28);
  --height-32: var(--numbers-32);
  --height-40: var(--numbers-40);
  --height-48: var(--numbers-48);
  --height-56: var(--numbers-56);
  --height-64: var(--numbers-64);
  --height-72: var(--numbers-72);
  --height-80: var(--numbers-80);
  --height-96: var(--numbers-96);
  --height-128: var(--numbers-128);
  --height-160: var(--numbers-160);
  --height-180: var(--numbers-180);
  --height-200: var(--numbers-200);
  --border-radius-0: var(--numbers-0);
  --border-radius-2: var(--numbers-2);
  --border-width-1: var(--numbers-1);
  --border-width-1-25: var(--numbers-1-25);
  --border-width-1-5: var(--numbers-1-5);
  --border-width-2: var(--numbers-2);
  --border-width-4: var(--numbers-4);
  --border-radius-4: var(--numbers-4);
  --border-radius-6: var(--numbers-6);
  --border-radius-8: var(--numbers-8);
  --border-radius-12: var(--numbers-12);
  --border-radius-16: var(--numbers-16);
  --border-radius-20: var(--numbers-20);
  --border-radius-28: var(--numbers-28);
  --border-radius-full: var(--numbers-full);
  --border-thickness-icon-8-12: var(--border-width-1);
  --border-thickness-icon-16: var(--border-width-1-25);
  --border-thickness-icon-20: var(--border-width-1-5);
  --border-thickness-icon-24-32: var(--border-width-1-5);
  --border-thickness-separator: var(--border-width-1);
  --border-thickness-border-01: var(--border-width-1);
  --border-thickness-border-015: var(--border-width-1-5);
  --border-thickness-border-02: var(--border-width-2);
  --border-radius-button: var(--border-radius-6);
  --border-radius-checkbox: var(--border-radius-4);
  --border-radius-dropdown: var(--border-radius-6);
  --border-radius-dropdown-tile: var(--border-radius-4);
  --border-radius-input: var(--border-radius-6);
  --border-radius-tab: var(--border-radius-6);
  --border-radius-tab-bar: var(--border-radius-8);
  --border-radius-tag: var(--border-radius-4);
  --border-radius-tip: var(--border-radius-8);
  --border-radius-modal: var(--border-radius-12);
  --gap-tab-group-gap: var(--space-6);
  --gap-buttons-in-line-gap: var(--space-12);
  --gap-ghost-buttons-in-line-gap: var(--space-4);
  --gap-inputs-in-line-gap: var(--space-20);
  --shadow-y-position-shadow-01: var(--numbers-1);
  --shadow-y-position-shadow-015: var(--numbers-1-5);
  --shadow-y-position-shadow-02: var(--numbers-2);
  --shadow-y-position-shadow-04: var(--numbers-4);
  --shadow-y-position-shadow-06: var(--numbers-6);
  --shadow-y-position-shadow-08: var(--numbers-8);
  --shadow-y-position-shadow-12: var(--numbers-12);
  --shadow-y-position-shadow-16: var(--numbers-16);
  --shadow-y-position-shadow-20: var(--numbers-20);
  --shadow-y-position-shadow-32: var(--numbers-32);
  --shadow-blur-shadow-01: var(--numbers-1);
  --shadow-blur-shadow-015: var(--numbers-1-5);
  --shadow-blur-shadow-02: var(--numbers-4);
  --shadow-blur-shadow-04: var(--numbers-4);
  --shadow-blur-shadow-06: var(--numbers-8);
  --shadow-blur-shadow-08: var(--numbers-12);
  --shadow-blur-shadow-12: var(--numbers-16);
  --shadow-blur-shadow-16: var(--numbers-20);
  --shadow-blur-shadow-20: var(--numbers-32);
  --shadow-blur-shadow-32: var(--numbers-56);
  --numbers-0: 0px;
  --numbers-1: 1px;
  --numbers-1-25: 1.25px;
  --numbers-1-5: 1.5px;
  --numbers-2: 2px;
  --numbers-4: 4px;
  --numbers-6: 6px;
  --numbers-8: 8px;
  --numbers-12: 12px;
  --numbers-16: 16px;
  --numbers-20: 20px;
  --numbers-24: 24px;
  --numbers-28: 28px;
  --numbers-32: 32px;
  --numbers-40: 40px;
  --numbers-48: 48px;
  --numbers-56: 56px;
  --numbers-64: 64px;
  --numbers-72: 72px;
  --numbers-80: 80px;
  --numbers-96: 96px;
  --numbers-128: 128px;
  --numbers-160: 160px;
  --numbers-180: 180px;
  --numbers-200: 200px;
  --numbers-full: 9999px;
}

.dark {
  --color-text-general-primary: var(--color-grey-white);
  --color-text-general-secondary: var(--color-grey-grey-6);
  --color-text-general-tertiary: var(--color-grey-grey-8);
  --color-text-general-disabled: var(--color-grey-grey-10);
  --color-text-general-inverse: var(--color-grey-black);
  --color-text-general-white: var(--color-grey-white);
  --color-text-general-action: var(--color-blue-blue-8);
  --color-text-general-error: var(--color-red-red-8);
  --color-text-general-warning: var(--color-orange-orange-7);
  --color-text-general-positive: var(--color-green-green-6);
  --color-text-onbutton-disabled: var(--color-grey-grey-9);
  --color-text-onbutton-inverse: var(--color-grey-white);
  --color-text-onbutton-primary-default: var(--color-grey-black);
  --color-text-onbutton-primary-hover-active: var(--color-grey-black);
  --color-text-onbutton-primary-pressed: var(--color-grey-grey-2);
  --color-text-onbutton-secondary-default: var(--color-grey-grey-2);
  --color-text-onbutton-secondary-hover-active: var(--color-grey-grey-2);
  --color-text-onbutton-secondary-pressed: var(--color-grey-grey-2);
  --color-text-onbutton-ghost-default: var(--color-grey-grey-6);
  --color-text-onbutton-ghost-hover-active: var(--color-grey-grey-2);
  --color-text-onbutton-ghost-pressed: var(--color-grey-grey-2);
  --color-text-onbutton-error-default: var(--color-red-red-15);
  --color-text-onbutton-error-hover-active: var(--color-red-red-15);
  --color-text-onbutton-error-pressed: var(--color-grey-white);
  --color-text-onbutton-positive-default: var(--color-green-green-15);
  --color-text-onbutton-positive-hover-active: var(--color-green-green-15);
  --color-text-onbutton-positive-pressed: var(--color-grey-white);
  --color-text-onbutton-informative-default: var(--color-blue-blue-15);
  --color-text-onbutton-informative-hover-active: var(--color-blue-blue-15);
  --color-text-onbutton-informative-pressed: var(--color-grey-white);
  --color-text-ondropdownmenu-placeholder: var(--color-text-general-secondary);
  --color-text-onfilter-primary: var(--color-text-general-primary);
  --color-text-onfilter-secondary: var(--color-text-general-secondary);
  --color-text-onfilter-error: var(--color-red-red-5);
  --color-text-oninput-placeholder: var(--color-grey-grey-7);
  --color-text-oninput-active: var(--color-text-general-primary);
  --color-text-oninput-label: var(--color-text-general-primary);
  --color-text-oninput-error: var(--color-red-red-7);
  --color-text-oninput-helper: var(--color-text-general-tertiary);
  --color-text-oninput-disabled: var(--color-grey-grey-9);
  --color-text-ontip-default: var(--color-grey-black);
  --color-text-ontip-error: var(--color-red-red-4);
  --color-text-ontip-informative: var(--color-blue-blue-4);
  --color-text-ontip-positive: var(--color-green-green-3);
  --color-text-ontip-sandbox: var(--color-yellow-yellow-3);
  --color-text-ontip-error-emphasized: var(--color-grey-white);
  --color-text-ontab-default: var(--color-grey-grey-6);
  --color-text-ontab-hover: var(--color-grey-white);
  --color-text-ontab-active: var(--color-grey-white);
  --color-text-ontag-neutral: var(--color-grey-grey-5);
  --color-text-ontag-success: var(--color-green-green-5);
  --color-text-ontag-warning: var(--color-orange-orange-5);
  --color-text-ontag-error: var(--color-red-red-5);
  --color-text-ontag-informative: var(--color-blue-blue-5);
  --color-text-ontag-purple: var(--color-purple-purple-4);
  --color-text-ontag-contrast: var(--color-grey-black);
  --color-text-ontag-disabled: var(--color-grey-grey-10);
  --color-icon-general-primary: var(--color-grey-white);
  --color-icon-general-secondary: var(--color-grey-grey-8);
  --color-icon-general-tertiary: var(--color-grey-grey-9);
  --color-icon-general-disabled: var(--color-grey-grey-10);
  --color-icon-general-inverse: var(--color-grey-black);
  --color-icon-general-error: var(--color-red-red-9);
  --color-icon-general-positive: var(--color-green-green-6);
  --color-icon-general-action: var(--color-blue-blue-8);
  --color-icon-general-warning: var(--color-orange-orange-7);
  --color-icon-onbutton-disabled: var(--color-grey-grey-9);
  --color-icon-onbutton-inverse: var(--color-grey-white);
  --color-icon-onbutton-primary-default: var(--color-grey-black);
  --color-icon-onbutton-primary-hover-active: var(--color-grey-black);
  --color-icon-onbutton-primary-pressed: var(--color-grey-grey-2);
  --color-icon-onbutton-secondary-default: var(--color-grey-grey-2);
  --color-icon-onbutton-secondary-hover-active: var(--color-grey-grey-2);
  --color-icon-onbutton-secondary-pressed: var(--color-grey-grey-2);
  --color-icon-onbutton-ghost-default: var(--color-grey-grey-8);
  --color-icon-onbutton-ghost-hover-active: var(--color-grey-grey-2);
  --color-icon-onbutton-ghost-pressed: var(--color-grey-grey-2);
  --color-icon-onbutton-error-default: var(--color-red-red-15);
  --color-icon-onbutton-error-hover-active: var(--color-red-red-15);
  --color-icon-onbutton-error-pressed: var(--color-grey-white);
  --color-icon-onbutton-positive-default: var(--color-green-green-15);
  --color-icon-onbutton-positive-hover-active: var(--color-green-green-15);
  --color-icon-onbutton-positive-presseed: var(--color-grey-white);
  --color-icon-onbutton-informative-default: var(--color-blue-blue-15);
  --color-icon-onbutton-informative-hover-active: var(--color-blue-blue-15);
  --color-icon-onbutton-informative-pressed: var(--color-grey-white);
  --color-icon-onfilter-primary: var(--color-icon-general-primary);
  --color-icon-onfilter-secondary: var(--color-icon-general-secondary);
  --color-icon-onfilter-error: var(--color-red-red-5);
  --color-icon-oninput-default: var(--color-grey-white);
  --color-icon-oninput-error: var(--color-red-red-7);
  --color-icon-oninput-disabled: var(--color-grey-grey-9);
  --color-icon-oninput-default-filled: var(--color-grey-grey-8);
  --color-icon-oninput-active: var(--color-grey-white);
  --color-icon-oninput-placeholder: var(--color-grey-grey-7);
  --color-icon-onmainmenu-default: var(--color-grey-grey-8);
  --color-icon-onmainmenu-hover: var(--color-grey-grey-8);
  --color-icon-onmainmenu-active: var(--color-grey-white);
  --color-icon-onmainmenu-logo: var(--color-grey-white);
  --color-icon-ontip-default: var(--color-grey-black);
  --color-icon-ontip-error: var(--color-red-red-4);
  --color-icon-ontip-informative: var(--color-blue-blue-4);
  --color-icon-ontip-positive: var(--color-green-green-3);
  --color-icon-ontip-sandbox: var(--color-yellow-yellow-3);
  --color-icon-ontip-error-emphasized: var(--color-grey-white);
  --color-icon-ontag-neutral: var(--color-grey-grey-5);
  --color-icon-ontag-success: var(--color-green-green-5);
  --color-icon-ontag-warning: var(--color-orange-orange-5);
  --color-icon-ontag-error: var(--color-red-red-6);
  --color-icon-ontag-informative: var(--color-blue-blue-5);
  --color-icon-ontag-purple: var(--color-purple-purple-4);
  --color-icon-ontag-contrast: var(--color-grey-black);
  --color-icon-ontag-disabled: var(--color-grey-grey-10);
  --color-surface-background-bg-primary: var(--color-grey-grey-14);
  --color-surface-background-bg-secondary: var(--color-grey-grey-15);
  --color-surface-background-bg-tertiary: var(--color-grey-grey-16);
  --color-surface-background-bg-quarternary: var(--color-grey-grey-16);
  --color-surface-background-elevated: var(--color-grey-grey-13);
  --color-surface-background-success: var(--color-green-green-14);
  --color-surface-background-success-subtle: var(--color-green-green-15);
  --color-surface-background-warning: var(--color-orange-orange-14);
  --color-surface-background-warning-subtle: var(--color-orange-orange-16);
  --color-surface-background-error: var(--color-red-red-14);
  --color-surface-background-error-subtle: var(--color-red-red-16);
  --color-surface-background-informative: var(--color-blue-blue-15);
  --color-surface-background-informative-subtle: var(--color-blue-blue-16);
  --color-surface-background-darkout-01: var(--opacity-light-4);
  --color-surface-background-darkout-02: var(--opacity-light-6);
  --color-surface-background-darkout-03: var(--opacity-light-6);
  --color-surface-background-darkout-04: var(--opacity-light-12);
  --color-surface-background-darkout-05: var(--opacity-light-12);
  --color-surface-background-darkout-06: var(--opacity-light-16);
  --color-surface-background-modal-darkout: var(--opacity-dark-80);
  --color-surface-button-disabled: var(--color-grey-grey-12);
  --color-surface-counter-counter: var(--color-grey-grey-10);
  --color-surface-dropdown-menu-hover: var(--opacity-light-8);
  --color-surface-dropdown-menu-active: var(--opacity-light-6);
  --color-surface-dropdown-menu-active-hover: var(--opacity-light-8);
  --color-surface-dropdown-menu-pressed: var(--opacity-light-12);
  --color-surface-dropdown-menu-bg: var(--color-grey-grey-13);
  --color-surface-button-primary-default: var(--color-grey-white);
  --color-surface-button-primary-hover-active: var(--color-grey-grey-6);
  --color-surface-button-primary-pressed: var(--color-grey-grey-10);
  --color-surface-button-secondary-default: var(--opacity-light-8);
  --color-surface-button-secondary-hover-active: var(--opacity-light-12);
  --color-surface-button-secondary-pressed: var(--opacity-light-6);
  --color-surface-button-ghost-hover-active: var(--opacity-light-8);
  --color-surface-button-ghost-pressed: var(--opacity-light-12);
  --color-surface-button-error-default: var(--color-red-red-7);
  --color-surface-button-error-hover-active: var(--color-red-red-9);
  --color-surface-button-error-pressed: var(--color-red-red-10);
  --color-surface-button-positive-default: var(--color-green-green-5);
  --color-surface-button-positive-hover-active: var(--color-green-green-7);
  --color-surface-button-positive-pressed: var(--color-green-green-9);
  --color-surface-button-informative-default: var(--color-blue-blue-6);
  --color-surface-button-informative-hover-active: var(--color-blue-blue-8);
  --color-surface-button-informative-pressed: var(--color-blue-blue-11);
  --color-surface-check-radio-default: var(--opacity-dark-20);
  --color-surface-check-radio-hover: var(--opacity-dark-96);
  --color-surface-check-radio-active: var(--color-grey-white);
  --color-surface-check-radio-focus-helper: var(--color-grey-white);
  --color-surface-check-radio-error: var(--color-red-red-15);
  --color-surface-check-radio-disabled: var(--color-grey-grey-12);
  --color-surface-filter-active-default: var(--opacity-light-8);
  --color-surface-filter-active-hover: var(--opacity-light-12);
  --color-surface-filter-active-pressed: var(--opacity-light-6);
  --color-surface-filter-error-default: var(--color-red-red-13);
  --color-surface-filter-error-hover: var(--color-red-red-12);
  --color-surface-filter-error-pressed: var(--color-red-red-15);
  --color-surface-input-default-filled: var(--opacity-light-8);
  --color-surface-input-active: var(--opacity-light-8);
  --color-surface-input-hover-filled: var(--opacity-light-8);
  --color-surface-input-error: var(--color-red-red-15);
  --color-surface-input-disabled: var(--opacity-light-4);
  --color-surface-main-menu-hover: var(--opacity-light-6);
  --color-surface-main-menu-active: var(--opacity-light-6);
  --color-surface-main-menu-bg: var(--color-grey-grey-14);
  --color-surface-modal-modal-bg: var(--color-grey-grey-14);
  --color-surface-routing-card-true: var(--color-green-green-16);
  --color-surface-routing-card-ab-test: var(--color-blue-blue-16);
  --color-surface-routing-card-filter: var(--color-purple-purple-16);
  --color-surface-routing-card-parameter: var(--color-grey-grey-15);
  --color-surface-routing-card-authentication: var(--color-orange-orange-16);
  --color-surface-routing-card-block: var(--color-red-red-16);
  --color-surface-routing-tag-group-true: var(--opacity-light-4);
  --color-surface-routing-tag-group-ab-test: var(--opacity-light-4);
  --color-surface-routing-tag-group-filter: var(--opacity-light-4);
  --color-surface-routing-tag-group-parameter: var(--opacity-light-4);
  --color-surface-routing-tag-group-authentication: var(--opacity-light-4);
  --color-surface-routing-tag-group-block: var(--opacity-light-4);
  --color-surface-routing-thumbnail-true: var(--color-green-green-13);
  --color-surface-routing-thumbnail-ab-test: var(--color-blue-blue-13);
  --color-surface-routing-thumbnail-filter: var(--color-purple-purple-14);
  --color-surface-routing-thumbnail-parameter: var(--opacity-light-12);
  --color-surface-routing-thumbnail-authentication: var(--color-orange-orange-13);
  --color-surface-routing-thumbnail-block: var(--color-red-red-13);
  --color-surface-scroll-bar-default: var(--opacity-light-12);
  --color-surface-scroll-bar-hover-pressing: var(--opacity-light-24);
  --color-surface-tab-hover: var(--opacity-dark-28);
  --color-surface-tab-active: var(--opacity-light-12);
  --color-surface-tag-tag-neutral: var(--opacity-light-8);
  --color-surface-toast-default: var(--color-grey-grey-3);
  --color-surface-toast-error: var(--color-red-red-14);
  --color-surface-toast-informative: var(--color-blue-blue-16);
  --color-surface-toast-positive: var(--color-green-green-14);
  --color-surface-toast-sandbox: var(--color-yellow-yellow-13);
  --color-surface-toast-error-emphasized: var(--color-red-red-14);
  --color-surface-toast-button-hover-ondefault: var(--opacity-dark-8);
  --color-surface-toast-button-pressed-ondefault: var(--opacity-dark-12);
  --color-surface-toast-button-hover-oncolor: var(--opacity-dark-28);
  --color-surface-toast-button-pressed-oncolor: var(--opacity-dark-48);
  --color-surface-tag-tag-success: var(--color-green-green-13);
  --color-surface-tag-tag-warning: var(--color-orange-orange-13);
  --color-surface-tag-tag-error: var(--color-red-red-14);
  --color-surface-tag-tag-informative: var(--color-blue-blue-15);
  --color-surface-tag-tag-purple: var(--color-purple-purple-14);
  --color-surface-tag-tag-contrast: var(--color-grey-white);
  --color-surface-tag-tag-button-hover: var(--opacity-light-8);
  --color-surface-tag-tag-button-pressed: var(--opacity-light-16);
  --color-border-general-border-01: var(--opacity-light-8);
  --color-border-input-default: var(--opacity-light-16);
  --color-border-input-hover: var(--opacity-light-32);
  --color-border-input-active: var(--color-grey-white);
  --color-border-input-error: var(--color-red-red-7);
  --color-border-input-disabled: var(--color-grey-grey-9);
  --color-border-general-border-02: var(--opacity-light-12);
  --color-border-general-border-03: var(--opacity-light-16);
  --color-border-general-border-04: var(--opacity-light-20);
  --color-border-general-border-focus: var(--opacity-light-32);
  --color-border-general-border-contrast: var(--color-grey-white);
  --color-border-general-border-error: var(--color-red-red-10);
  --color-border-button-focus-helper: var(--color-grey-black);
  --color-border-button-onprimary: var(--opacity-dark-8);
  --color-border-button-onsecondary: var(--opacity-light-8);
  --color-border-button-toastbuttononcolor: var(--opacity-light-6);
  --color-border-button-toastbuttonondefault: var(--opacity-dark-8);
  --color-border-table-default: var(--color-grey-grey-13);
  --color-border-table-header: var(--color-grey-grey-12);
  --color-border-routing-connection-line: var(--opacity-light-8);
  --color-border-routing-border-true: var(--opacity-light-8);
  --color-border-routing-border-parameter: var(--opacity-light-8);
  --color-border-routing-border-filter: var(--opacity-light-12);
  --color-border-routing-border-authentication: var(--opacity-light-8);
  --color-border-routing-border-abtest: var(--opacity-light-12);
  --color-border-routing-border-block: var(--opacity-light-8);
  --color-border-check-radio-default: var(--opacity-light-24);
  --color-border-check-radio-hover: var(--opacity-light-32);
  --color-border-check-radio-focus: var(--color-blue-blue-8);
  --color-border-check-radio-focus-helper: var(--opacity-dark-28);
  --color-border-check-radio-disabled: var(--color-grey-grey-9);
  --color-border-check-radio-error: var(--color-red-red-8);
  --color-surface-toggle-default: var(--opacity-light-16);
  --color-surface-toggle-active: var(--color-grey-white);
  --color-icon-check-radio-active: var(--color-grey-black);
  --color-surface-toggle-disabled: var(--opacity-light-8);
  --color-icon-check-radio-disabled: var(--color-grey-grey-9);
  --color-surface-toggle-knob-default: var(--color-grey-white);
  --color-surface-toggle-knob-active: var(--color-grey-black);
  --color-surface-toggle-knob-disabled: var(--opacity-light-12);
  --shadow-color-shadow-01: var(--opacity-dark-24);
  --shadow-color-shadow-015: var(--opacity-dark-24);
  --shadow-color-shadow-02: var(--opacity-dark-20);
  --shadow-color-shadow-04: var(--opacity-dark-20);
  --shadow-color-shadow-06: var(--opacity-dark-20);
  --shadow-color-shadow-08: var(--opacity-dark-20);
  --shadow-color-shadow-12: var(--opacity-dark-32);
  --shadow-color-shadow-16: var(--opacity-dark-32);
  --shadow-color-shadow-20: var(--opacity-dark-64);
  --shadow-color-shadow-32: var(--opacity-dark-64);
  --border-thickness-icon-8-12: var(--border-width-1);
  --border-thickness-icon-16: var(--border-width-1-25);
  --border-thickness-icon-20: var(--border-width-1-5);
  --border-thickness-icon-24-32: var(--border-width-1-5);
  --border-thickness-separator: var(--border-width-1);
  --border-thickness-border-01: var(--border-width-1);
  --border-thickness-border-015: var(--border-width-1-5);
  --border-thickness-border-02: var(--border-width-2);
  --border-radius-button: var(--border-radius-6);
  --border-radius-checkbox: var(--border-radius-4);
  --border-radius-dropdown: var(--border-radius-6);
  --border-radius-dropdown-tile: var(--border-radius-4);
  --border-radius-input: var(--border-radius-6);
  --border-radius-tab: var(--border-radius-6);
  --border-radius-tab-bar: var(--border-radius-8);
  --border-radius-tag: var(--border-radius-4);
  --border-radius-tip: var(--border-radius-8);
  --border-radius-modal: var(--border-radius-12);
  --gap-tab-group-gap: var(--space-6);
  --gap-buttons-in-line-gap: var(--space-12);
  --gap-ghost-buttons-in-line-gap: var(--space-4);
  --gap-inputs-in-line-gap: var(--space-20);
  --shadow-y-position-shadow-01: var(--numbers-1);
  --shadow-y-position-shadow-015: var(--numbers-1-5);
  --shadow-y-position-shadow-02: var(--numbers-2);
  --shadow-y-position-shadow-04: var(--numbers-4);
  --shadow-y-position-shadow-06: var(--numbers-6);
  --shadow-y-position-shadow-08: var(--numbers-8);
  --shadow-y-position-shadow-12: var(--numbers-12);
  --shadow-y-position-shadow-16: var(--numbers-16);
  --shadow-y-position-shadow-20: var(--numbers-20);
  --shadow-y-position-shadow-32: var(--numbers-32);
  --shadow-blur-shadow-01: var(--numbers-1);
  --shadow-blur-shadow-015: var(--numbers-1-5);
  --shadow-blur-shadow-02: var(--numbers-4);
  --shadow-blur-shadow-04: var(--numbers-4);
  --shadow-blur-shadow-06: var(--numbers-8);
  --shadow-blur-shadow-08: var(--numbers-12);
  --shadow-blur-shadow-12: var(--numbers-16);
  --shadow-blur-shadow-16: var(--numbers-20);
  --shadow-blur-shadow-20: var(--numbers-32);
  --shadow-blur-shadow-32: var(--numbers-56);
}
