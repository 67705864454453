.payment-timeline {
  @apply relative;

  .payment-timeline-item {
    @apply relative flex justify-end;
    &:not(:last-child) {
      @apply pb-4;
    }

    /* Hide the grey line if there is just one card */
    &:only-child .payment-timeline-item-card:before {
      display: none;
    }

    &:before {
      /*
         * To calculate the grey line location, we want the width of 3 columns in a 12 column system (bearing in mind the gaps),
         * + the 3 gaps, - padding from logo to text content, - gap * logo width (see figma with grid setup)
         * e.g for small screen:
         * ((full-width - 11 gaps * gap size) / 12 cols * 4 cols) + (4 gaps * gap size) - padding from logo to text content - gap * logo width
        */
      @apply absolute left-[calc(((100%-11*1rem)/12*4)+(4*1rem)-0.750rem-0.625rem)] top-4 h-full w-[0.125rem] bg-border-subtle content-[""] lg:left-[calc(((100%-11*1rem)/12*3)+(3*1rem)-1rem-0.625rem)];
    }

    &:last-child:before {
      @apply bg-transparent;
    }

    &:first-child .payment-timeline-item-card:before {
      top: calc(2rem + 0.625rem); /* padding + one half of the icon size */
      height: calc(100% - 2.625rem); /* full height minus the top value */
    }

    &:last-child .payment-timeline-item-card:before {
      height: calc(2rem + 0.625rem); /* padding + one half of the icon size */
    }

    .payment-timeline-item-card {
      /*
       * To calculate the card size, we want the width of 9 columns in a 12 column system (bearing in mind the gaps),
       * + the extra width to the left of the text content (see figma with grid setup)
       * e.g for small screen:
       * ((full-width - 11 gaps * gap size) / 12 cols * 8 cols) + (7 gaps * gap size) + extra card width
      */
      @apply hover:surface-1 relative -top-8 right-0 z-10 flex h-full w-[calc(((100%-11*1rem)/12*8)+(7*1rem)+4rem)] items-start rounded-3xl border border-transparent p-8 lg:w-[calc(((100%-11*1rem)/12*9)+(8*1rem)+4.25rem)];

      &:before {
        @apply absolute top-0 -z-10 h-full w-[0.125rem] bg-border-subtle content-[""];
        left: calc(
          2rem + 0.625rem - 0.0625rem
        ); /* padding + one half of the icon size - half of this line width */
      }
    }

    /* When card accordion is opened */
    &[data-state="open"] {
      .payment-timeline-item-card {
        @apply cursor-default;
      }

      .payment-timeline-item-card {
        @apply surface-1 border-border-subtle;
      }

      &:last-child .payment-timeline-item-card:before {
        height: 100%;
      }

      .payment-timeline-item-card:before {
        @apply bg-gradient-to-b from-timeline-line-neutral to-timeline-line-neutral/10;
      }

      &.success .payment-timeline-item-card:before {
        @apply from-timeline-line-success to-timeline-line-success/10;
      }

      &.failed .payment-timeline-item-card:before {
        @apply from-timeline-line-error to-timeline-line-error/10;
      }

      &.pending .payment-timeline-item-card:before {
        @apply from-timeline-line-warning to-timeline-line-warning/10;
      }
    }
  }
}
