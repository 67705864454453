.checkbox {
  input[type="checkbox"] {
    @apply mr-2 box-content h-4 w-4 cursor-pointer appearance-none rounded border border-border-default bg-surface-0;

    &:hover {
      @apply border-action-border-hover;
    }

    &:disabled {
      @apply cursor-default border-action-border-disabled bg-action-primary-disabled;
    }

    &:checked,
    &:indeterminate {
      @apply border-transparent bg-action-primary;

      &:before {
        /* Using mask allows using background-color to set the fill of the SVG in the mask-image */
        @apply block h-4 w-4 bg-checkbox-svg content-[""];
        mask-position: 50%;
        mask-repeat: no-repeat;
        mask-size: 0.75rem 0.75rem;
      }

      &:hover {
        @apply bg-action-primary-hover;
      }

      &:disabled {
        @apply bg-action-primary-disabled;

        &:before {
          @apply bg-checkbox-svg-disabled;
        }
      }
    }

    &:indeterminate {
      &:before {
        mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6C1 5.44772 1.44772 5 2 5H10C10.5523 5 11 5.44772 11 6C11 6.55228 10.5523 7 10 7H2C1.44772 7 1 6.55228 1 6Z'/%3E%3C/svg%3E");
      }
    }

    &:checked {
      &:before {
        mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8321 2.66789C11.2226 3.05841 11.2226 3.69158 10.8321 4.0821L5.58216 9.3321C5.39462 9.51964 5.14027 9.625 4.87505 9.625C4.60983 9.625 4.35548 9.51964 4.16794 9.33211L1.54294 6.70711C1.15242 6.31658 1.15242 5.68342 1.54294 5.29289C1.93347 4.90237 2.56663 4.90237 2.95716 5.29289L4.87505 7.21078L9.41789 2.6679C9.80841 2.27737 10.4416 2.27737 10.8321 2.66789Z'/%3E%3C/svg%3E");
      }
    }
  }
}
