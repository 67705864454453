.display {
  @apply text-display-medium leading-display-medium 2xl:text-display-large 2xl:leading-display-large;
}

.headline {
  @apply text-headline-medium leading-headline-medium 2xl:text-headline-large 2xl:leading-headline-large;
}

.title {
  @apply text-title-medium leading-title-medium 2xl:text-title-large 2xl:leading-title-large;
}

.subtitle {
  @apply text-subtitle-medium leading-subtitle-medium 2xl:text-subtitle-large 2xl:leading-subtitle-large;
}

.caption {
  @apply text-caption leading-caption;
}

.tag {
  @apply text-tag leading-tag;
}

.button {
  @apply text-button leading-button;
}

.tabular {
  @apply text-tabular leading-tabular;
}

.body {
  @apply text-body leading-body;
}

.label {
  @apply text-label leading-label;
}

.label-heading {
  @apply text-label-heading leading-label-heading;
}

.section-header {
  @apply text-section-header font-medium leading-section-header;
}
