@layer components {
  table {
    @apply border-separate border-spacing-0 text-text-primary;
  }

  /* To remove any padding in the table cell */
  table td,
  table th {
    @apply p-0;
  }

  table thead th {
    @apply font-medium;
  }

  table caption.sticky {
    @apply top-0 z-10 bg-surface-0;
  }

  table thead.sticky {
    @apply top-0 z-20;
  }

  /* 
  The background color should not be applied to thead.
  The purpose of the cell background color is to cover the drop shadow correctly.
  */
  table thead.sticky th {
    @apply bg-surface-0;
  }

  table thead th:nth-of-type(2).sticky {
    @apply left-[calc(45px-0.5rem)];
  }

  /* body */
  table tbody td.sticky {
    @apply bg-surface-0;
  }

  table tbody td:nth-of-type(2).sticky {
    @apply left-[calc(45px-0.5rem)];
  }

  table thead tr th {
    @apply border-t;
    @apply border-t-divider;
  }

  table thead tr th:first-of-type {
    @apply rounded-tl-2xl;
    @apply border-l border-l-divider;
  }

  table thead tr th:last-of-type {
    @apply rounded-tr-2xl;
    @apply border-r border-r-divider;
  }

  table thead tr th {
    @apply border-b border-b-divider;
  }

  /* body */
  table tbody tr td:first-of-type {
    @apply border-l border-l-divider;
  }

  table tbody tr td:last-of-type {
    @apply border-r border-r-divider;
  }

  table tbody tr td {
    @apply border-b border-b-divider;
  }

  /* footer */
  table tfoot tr td:first-of-type {
    @apply rounded-bl-2xl;
    @apply border-l border-l-divider;
  }

  table tfoot tr td:last-of-type {
    @apply rounded-br-2xl;
    @apply border-r border-r-divider;
  }

  table tfoot tr td {
    @apply border-b border-b-divider;
  }

  table thead.sticky-show:after {
    @apply absolute -z-10 w-full scale-[0.994] content-[""];
    box-shadow: 0 0 12px 1px rgb(0 0 0 / 35%);
  }
}
