@import "./base/focus.css";
@import "./base/themes.css";
@import "./base/designTokens.css";

@import "./components/Accordion.css";
@import "./components/Checkbox.css";
@import "./components/Collapsible.css";
@import "./components/DatePicker.css";
@import "./components/PaymentTimeline.css";
@import "./components/ReactSelect.css";
@import "./components/surfaces.css";
@import "./components/TransactionTable.css";
@import "./components/typography.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --radius: 0.5rem;
  }

  input[data-com-onepassword-filled],
  input[data-com-onepassword-filled]:hover,
  input[data-com-onepassword-filled]:focus,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    font-family: inherit;
    background-clip: text;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
  }
}
